<template>
  <div class="MyList">
    <AdminStatisticsFilter :title="$locale['susbcriptions_by_creator']" :statistics="statistics" />
    <div :class="`statistics-block __general ${$loading ? '__loading' : '__loaded'}`">
      <div class="ReportInfo">
        <LargeButton
          class="MyListCard"
          v-for="(transaction, index) in items"
          :key="index"
          :label="`<span third-bold>@${transaction.creator && transaction.creator.user}</span>`"
          :desc="`<div><div date>${date_locale(transaction.date)}</div></div>`"
          :icon="false"
          :rightDesc="`<strong nowrap mount>$ ${transaction.mount}</strong><small>${$locale['usd']}</small>`"
          :UserAvatar="transaction.creator"
          iconSize="48px"
          prevent
        />
        <LoadMore :morepage="statistics.hasMore" @more="updateFeed({ page: statistics.page + 1 })" />
        <CardMessage v-if="!$loading && !statistics.total && listed" :message="$locale['nothing_found']" />
      </div>
    </div>
  </div>
</template>

<script>
import FeedList from "@/components/feed/feed.list";
import UserFeed from "@/components/user/UserFeed";
export default {
  mixins: [UserFeed, FeedList],
  components: {
    AdminStatisticsFilter: () => import("./AdminStatisticsFilter.vue"),
  },
  data: function() {
    return {
      endpoint: `${process.env.VUE_APP_API_HOST}/admin/admin-subscriptions-by-creator`,
      storeId: "my_list",
      items: [],
      statistics: { total: 0, page: 1 },
      limit: 20,
      axios: {
        headers: {
          "use-form": "false",
        },
      },
    };
  },
};
</script>

<style lang="scss">
.MyList {
  position: relative;
}
</style>
